<template>
  <div class="list-all-courses">
    <Loading v-if="!academicCourses.length && !summerCourses.length" />
    <content-not-view v-else-if="!subPermission.index" />
    <div v-else>
      <div class="course-header">
        <h1 class="title">{{ $t("g.classrooms/allCourses/courses") }}</h1>
        <b-button
          variant="primary"
          @click="$router.push({ name: 'courseRequests' })"
        >
          {{ $t("g.classrooms/allCourses/view-request") }}
        </b-button>
      </div>
      <AcademicCourse :academicCourses="academicCourses" />
      <SummerCourse
        @call-back="getSummerCourses"
        :summerCourses="summerCourses"
        :permission="subPermission"
      />
    </div>
  </div>
</template>

<script>
import AcademicCourse from "@/components/classrooms/course/AcademicCourse/index.vue";
import CourseCardComponent from "@/components/classrooms/course/CourseCardComponent";
import { BButton, BCol, BRow } from "bootstrap-vue";
import Loading from "@/components/loading/loading.vue";
import SummerCourse from "@/components/classrooms/course/SummerCourse/index.vue";
import contentNotView from "@/components/contentNotView/contentNotView.vue";

export default {
  name: "AllCourses",
  components: {
    BCol,
    BRow,
    BButton,
    Loading,
    SummerCourse,
    AcademicCourse,
    CourseCardComponent,
    contentNotView,
  },
  data() {
    return {
      academicCourses: [],
      summerCourses: [],
    };
  },
  beforeMount() {
    this.getAcademicCourses();
    this.getSummerCourses();
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSION", "courses");
      return this.$store.state.userData.sub_Permission;
    },
  },
  methods: {
    getAcademicCourses() {
      this.$http
        .get("/admin/edu-years")
        .then((response) => {
          this.academicCourses = response.data.data;
        })
        .catch((error) => {
          console.log(error);

          this.$helpers.errorHandler(error);
        });
    },
    getSummerCourses() {
      this.$http
        .get("/admin/courses?is_summer=true")
        .then((response) => {
          this.summerCourses = [];
          response.data.data.map((el) => {
            if (el.is_approved) {
              this.summerCourses.push(el);
            }
          });
        })
        .catch((error) => {
          console.log(error);
          this.$helpers.errorHandler(error);
        });
    },
  },
};
</script>

<style lang="scss" src="./_index.scss" />
