<template>
  <div class="list-summer-courses">
    <b-row class="summer-courses-header">
      <feather-icon
        :icon="isCollapsed ? 'ChevronUpIcon' : 'ChevronDownIcon'"
        size="18"
        class="mr-1 cursor-pointer"
        @click="toggleCollapse"
      />
      <p>{{ $t("g.classrooms/allCourses/summer-courses") }}</p>
      <hr class="w-75 border-2" />
    </b-row>
    <b-collapse id="summer-courses" visible>
      <course-card-component
        :coursesData="summerCourses"
        @createCourse="createCourse"
        :permission="permission"
        @call-back="$emit('call-back')"
      />
    </b-collapse>
  </div>
</template>

<script>
import { BButton, BCard, BCardText, BCollapse, BRow } from "bootstrap-vue";
import CourseCardComponent from "@/components/classrooms/course/CourseCardComponent/index.vue";

export default {
  name: "SummerCourse",
  components: {
    CourseCardComponent,
    BRow,
    BCard,
    BButton,
    BCollapse,
    BCardText,
  },
  data() {
    return {
      isCollapsed: false,
    };
  },
  props: {
    summerCourses: {
      type: Array,
      default: () => [],
    },
    permission: {
      type: Object,
    },
  },
  methods: {
    toggleCollapse() {
      this.$root.$emit("bv::toggle::collapse", "summer-courses");
      this.isCollapsed = !this.isCollapsed;
    },
    createCourse() {
      this.$emit("call-back");
    },
  },
};
</script>

<style lang="scss" src="./_index.scss" />
